import { jsNode } from '@shared/node-v1.0.0'

import v200 from '@nodes/update-v2.0.0'

export default jsNode(
	'update',
	{
		'v2.0.0': v200,
	},
	{ docs: '' }
)
